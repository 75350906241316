<template>
    <page-header-wrapper>
        <a-card>
            <div class="table-page-search-wrapper">
                <a-form layout="inline" class="table-head-ground">
                    <div class="table-layer">
                        <span class="table-page-search-submitButtons">
                            <a-button type="primary" icon="search" @click="queryFunc"
                                :loading="btnLoading">搜索</a-button>
                        </span>
                    </div>
                </a-form>
            </div>
            <!-- 列表渲染 -->
            <JeepayTable @btnLoadClose="btnLoading = false" ref="infoTable" :initData="true" :closable="true"
                :searchData="searchData" :reqTableDataFunc="reqTableDataFunc" :tableColumns="tableColumns"
                rowKey="refundOrderId" :scrollX="1300">
                <template slot="handleStateSlot" slot-scope="{record}">
                    {{ handleStateObj[record.handleState] }}
                </template>
                <template slot="opSlot" slot-scope="{record}"> <!-- 操作列插槽 -->
                    <JeepayTableColumns>
                        <a-button type="link" v-if="$access('ENT_DIVISION_ADAPAYMEMBER_EDIT')"
                            @click="download(record)">下载</a-button>
                    </JeepayTableColumns>
                </template>
            </JeepayTable>
        </a-card>
    </page-header-wrapper>
</template>
<script>
import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
import JeepayTable from '@/components/JeepayTable/JeepayTable'
import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
import { req, API_URL_RECONCILIATION_UPLOADFILE } from '@/api/manage'
import moment from 'moment'
import axios  from 'axios'
import storage from '@/utils/jeepayStorageWrapper'
import appConfig from '@/config/appConfig'
const tableColumns = [
    { key: 'fileName', title: '文件类型', dataIndex: "fileName", width: '180px' },
    { key: 'createAt', title: '导出时间', dataIndex: 'createAt', width: '180px', ellipsis: true, },
    { key: 'handleState', title: '处理状态', scopedSlots: { customRender: 'handleStateSlot' }, width: '180px' },
    { key: 'op', title: '操作', width: '250px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
]
export default {
    name: 'DownloadList',
    components: {
        JeepayTextUp,
        JeepayTable,
        JeepayTableColumns
    },
    data() {
        return {
            btnLoading: false,
            tableColumns: tableColumns,
            searchData: {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
                recordType: '1',//下载列表
            },
            handleStateObj: {
                "1": "处理中",
                "2": "处理成功",
                "3": "处理失败",
            },
            createdDate: [],
        }
    },
    mounted() {

    },
    methods: {
        queryFunc() {
            this.btnLoading = true
            this.$refs.infoTable.refTable(true)
        },
        // 请求table接口数据
        reqTableDataFunc: (params) => {
            return req.list(API_URL_RECONCILIATION_UPLOADFILE, params)
        },
        searchFunc() { // 点击【查询】按钮点击事件
            this.$refs.infoTable.refTable(true)
        },
        moment,
        reset() {
            this.searchData = {
                createdStart: '', // 选择开始时间
                createdEnd: '', // 选择结束时间
            }
            this.createdDate = [];
            setTimeout(() => { this.$refs.infoTable.refTable(true) }, 1000)
        },
        download(row) {
            let url = process.env.VUE_APP_API_BASE_URL + "/" + encodeURI(row.filePath);
            const headers = {}
            headers[appConfig.ACCESS_TOKEN_NAME] = storage.getToken()
            axios({
                url: url,
                method: 'get',
                responseType: 'blob',
                headers:headers
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.setAttribute('download', row.fileName + '..xlsx')
                link.href = url
                document.body.appendChild(link)
                link.click()
            })
        }
    }
}
</script>
<style scoped lang="less"></style>